<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="100px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')">
    <el-form-item label="电话号码" prop="phone">
      <el-col :md="9">
        <el-input v-model.trim="formModel.phone"></el-input>
      </el-col>
    </el-form-item>
    <el-form-item label="授权码" prop="auth_token">
      <el-col :md="6">
        <el-input type="number" v-model.trim="formModel.auth_token"></el-input>
      </el-col>
       <el-col v-if="isEdit" :md="18">
        <span class="form-help form-help-inline">授权码用作POS机需切换收款收银员时使用（留空表示不修改授权码）</span>
      </el-col>
    </el-form-item>
    <el-form-item label="密码" prop="password">
      <el-col :md="6">
        <el-input type="password" auto-complete="new-password" v-model.trim="formModel.password"></el-input>
      </el-col>
      <el-col v-if="isEdit" :md="18">
        <span class="form-help form-help-inline">密码留空表示不修改密码</span>
      </el-col>
    </el-form-item>
    <el-form-item label="重复密码" prop="passwordRepeat">
      <el-col :md="6">
        <el-input type="password" auto-complete="new-password" v-model.trim="formModel.passwordRepeat"></el-input>
      </el-col>
    </el-form-item>
     <el-form-item label="描述名" prop="name">
      <el-col :md="3">
        <el-input v-model.trim="formModel.name"></el-input>
      </el-col>
    </el-form-item>
    <el-form-item label="昵称" prop="username">
      <el-col :md="3">
        <el-input v-model.trim="formModel.username"></el-input>
      </el-col>
    </el-form-item>
    <el-form-item label="头像" prop="displayIcon">
      <uploader :avatar="formModel.avatar"
                @on-upload-success="handleUploadSuccess"></uploader>
    </el-form-item>
    <el-form-item label="店铺" v-if="formModel.id !== '1'" prop="groupId">
      <el-col>
        <el-select v-model="formModel.groupId"
                    multiple
                    collapse-tags
                   :disabled="getCurrentUserId === formModel.id"
                   placeholder="所属店铺">
          <el-option
            v-for="(item,index) in adminGroups"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-form-item>

    <el-form-item label="权限" v-if="role.length>0">
       <!-- <el-checkbox-group v-model="formModel.roles"> -->
        <el-radio
          v-for="(item,index) in role"
          :key="index"
          :label="item.name"
          v-model="formModel.roles"
          >
          {{item.des}}
        </el-radio>
      <!-- </el-checkbox-group> -->
    </el-form-item>
    <el-form-item>
      <el-button size="medium" type="primary" native-type="submit" :loading="submitLoading">{{ submitText }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Uploader from '@admin/components/uploaders/Uploader'

export default {
  name: 'AdminForm',
  components: { Uploader },
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    admin: {
      type: Object
    },
    adminGroups: {
      type: Array
    },
    role: {
      type: Array
    }
  },
  computed: {
    getCurrentUserId () {
      return this.$store.getters['auth/getCurrentUser'] ? this.$store.getters['auth/getCurrentUser'].id : 0
    }
  },
  data () {
    const validatePassword = []
    const validatePasswordRepeat = []

    if (!this.isEdit) {
      validatePassword.push({ required: !this.isEdit, message: '请输入密码', trigger: 'blur' })
      validatePasswordRepeat.push({ required: !this.isEdit, message: '请输入重复密码', trigger: 'blur' })
    }

    validatePassword.push({
      validator: (rule, value, callback) => {
        if (this.formModel.passwordRepeat !== '') {
          this.$refs.formModel.validateField('passwordRepeat')
        }
        callback()
      },
      trigger: 'blur'
    })

    validatePasswordRepeat.push({
      validator: (rule, value, callback) => {
        if (value !== this.formModel.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      },
      trigger: 'blur'
    })

    return {
      submitLoading: false,
      formRules: {
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入描述名', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
        password: validatePassword,
        passwordRepeat: validatePasswordRepeat,
        groupId: [
          { required: true, message: '请选择所属管理组', trigger: 'blur' }
        ]
      },
      formModel: null,
      roleIds: []
    }
  },
  async mounted () {
    this.formModel = Object.assign({ password: '', passwordRepeat: '' }, this.admin)
  },
  methods: {
    handleUploadSuccess (avatarUrl) {
      this.formModel.avatar = avatarUrl
    },
    handleFormSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit('on-submit', this.formModel, () => {
          this.$refs[formName].clearValidate()
        }, () => {
          this.submitLoading = false
        })
      })
    }
  }
}
</script>
